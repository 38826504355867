import React from "react";
import { Link } from "gatsby";

import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import youtube from "../img/social/youtube.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered"></div>
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list has-text-centered">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/what-we-do/">
                        What We Do
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/where-we-work/">
                        Where We Work
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/our-founder/">
                        Our Founder
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="navbar-item"
                        to="/what-is-the-bredesen-protocol/"
                      >
                        Bredesen Protocol
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/faq/">
                        Frequently Asked Questions
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/terms/">
                        Terms of Use
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list has-text-centered">
                    <li>
                      <Link className="navbar-item" to="/blog/">
                        Latest Blog Posts
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog/recipes/">
                        Recipes
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog/nutrition/">
                        Nutrition
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog/lifestyle/">
                        Lifestyle
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog/news/">
                        News
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a
                  title="facebook"
                  href="https://www.facebook.com/theamosinstitute"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={facebook}
                    alt="Amos Institute Facebook"
                    style={{ width: "1em", height: "1em" }}
                    className="inline align-baseline"
                  />
                </a>
                <a
                  title="twitter"
                  href="https://twitter.com/amosinstitute"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="fas fa-lg inline align-baseline"
                    src={twitter}
                    alt="Amos Institute Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a
                  title="instagram"
                  href="https://www.instagram.com/amosinstitute/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={instagram}
                    alt="Amos Institute Instagram"
                    style={{ width: "1em", height: "1em" }}
                    className="inline align-baseline"
                  />
                </a>
                <a
                  title="Youtube"
                  href="https://www.youtube.com/channel/UCZrfM0i8UmGMLCbO3_SaucQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={youtube}
                    alt="Amos Institute Youtube Channel"
                    style={{ width: "1em", height: "1em" }}
                    className="inline align-baseline"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
