import React from "react";
import Layout from "../../components/Layout";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import speechBubble from "../../img/logo-speech-bubble.svg";

const title = "Thank You For Contacting Us | Amos Institute";
export default () => (
  <Layout>
    <Helmet title={title}>
      <meta name="robots" content="noindex" />
    </Helmet>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-narrow">
            <img src={speechBubble} alt="Speaking to the Amos Institute" />
          </div>
          <div className="column">
            <div className="content">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                Your message has been sent!
              </h1>
              <p className="has-text-grey" style={{ maxWidth: "575px" }}>
                Thank you for contacting the Amos Institute. Our team will
                respond to you shortly. For answers to many of the general
                questions we receive, please visit our{" "}
                <Link to="/faq/">Frequently Asked Questions</Link>.
              </p>
              <p className="has-text-grey">
                If you do not receive a reply from us within 48 hours, please
                check your email's <strong>Spam</strong> folder.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
