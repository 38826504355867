import React from "react";
import { Link } from "gatsby";
import { login, logout, isAuthenticated } from "../utils/auth";
import navbarLogo from "../img/navbar-logo.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a href="/" className="navbar-item" title="Logo">
              <img src={navbarLogo} alt="Amos Institute Logo" />
            </a>
            {/* Hamburger menu */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/cognitive-health-program/">
                  About
                </Link>

                <div className="navbar-dropdown">
                  <Link to="/cognitive-health-program/" className="navbar-item">
                    Cognitive Health Program
                  </Link>
                  <Link to="/our-founder/" className="navbar-item">
                    Our Founder
                  </Link>
                  <hr className="navbar-divider" />
                  <span
                    style={{ paddingLeft: "1rem" }}
                    className="heading has-text-grey"
                  >
                    Resources
                  </span>
                  <Link
                    to="/what-is-the-bredesen-protocol/"
                    className="navbar-item"
                  >
                    Bredesen Protocol
                  </Link>
                  <Link to="/what-is-a-recode-report/" className="navbar-item">
                    ReCODE Report
                  </Link>
                  <Link
                    to="/can-alzheimers-disease-be-prevented/"
                    className="navbar-item"
                  >
                    Alzheimer's Prevention
                  </Link>
                  <Link
                    to="/the-subtypes-of-alzheimers-disease/"
                    className="navbar-item"
                  >
                    The Subtypes of Alzheimer's Disease
                  </Link>
                  <Link to="/what-is-apoe/" className="navbar-item">
                    What is ApoE?
                  </Link>
                  <Link
                    to="/dietitian-nutritionist-or-health-coach/"
                    className="navbar-item"
                  >
                    Dietitian, Nutritionist or Health Coach
                  </Link>
                </div>
              </div>
              <Link className="navbar-item" to="/pricing/">
                Pricing
              </Link>
              <Link className="navbar-item" to="/faq/">
                FAQs
              </Link>
              <Link className="navbar-item" to="/blog/">
                Blog
              </Link>
              <Link className="navbar-item" to="/contact/">
                Contact Us
              </Link>

              {isAuthenticated() ? (
                <React.Fragment>
                  <Link className="navbar-item has-text-primary" to="/account/">
                    <strong>My Account</strong>
                  </Link>
                  <div className="navbar-item">
                    <button className="button is-light" onClick={logout}>
                      Logout
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <div className="navbar-item">
                  <div className="buttons">
                    <Link
                      id="signup"
                      className="is-inst-blue button"
                      to="/signup/"
                    >
                      Sign Up
                    </Link>
                    <button
                      id="login"
                      className="button is-outlined"
                      onClick={login}
                    >
                      Log In
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
